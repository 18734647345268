html {
  height: 100vh;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand';
  /*background-color: #342E37; /*#FAFFFD; /* #fafafa;*/
  background-image: linear-gradient(#342E37, #0f0d0f);
}

.landing-bg {
  background-image: url(/static/media/holding-globe.611c45ba.jpg);
  background-attachment: scroll;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.App {
  /* For sticky footer */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  /* For fixed bg on android */
  min-height: 100vh;
  height: 100%;
}

main {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

h1, h2, h3, h4, h5 {
  font-family: 'Passion One';
}

.ribbon-stripes {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAPAgMAAACO1JcPAAAAAXNSR0IB2cksfwAAAAlQTFRFAAAA+v/9NC43Gdo7JgAAAAN0Uk5TAP//RFDWIQAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABVJREFUeJxjCA0NDVu1atUChgFlAAB6Ojs+4gcsCgAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  min-height: 15px;
}

nav {
  height: 64px !important;
}

nav .sidenav-trigger i, .sidenav-trigger {
  height: 64px !important;
  line-height: 64x !important;
}

.sidenav li a {
  color: #FAFFFD;
}

#nav-mobile-green li a {
  color: #7bc340;
}

.card {
  padding: 15px;
}

.card-title {
  font-family: 'Passion One';
}

.btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #93cd62;
}

.spinner-green-only {
  border-color: #93cd62;
}

.valign-container {
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.valign-content {
  -webkit-flex: 1 1;
          flex: 1 1;
  max-height: 100%;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 50px;
}

.nav-wrapper {
  padding: 0 25px 0 25px;
}

.container {
  padding-top: 25px;
}

.lobster {
  font-family: "Lobster";
}

#whatiswcc {
  min-height: 100vh;
}

/* COLORS AND BACKGROUND  */

.wcc-green {
  color: #7bc340;
}

.wcc-green-bg {
  background-color: #7bc340;
}

.wcc-white {
  color: #FAFFFD;
}

.wcc-white-bg {
  background-color: #FAFFFD;
}

.wcc-black {
  color: #342E37;
}

.wcc-black-bg {
  background-color: rgb(52, 46, 55);
}

.wcc-green-to-white {
  background-image: linear-gradient(to right, #7bc340, #FAFFFD, #FAFFFD);
}

.motive-bird {
  background: url(/static/media/jason-hafso-DS0ZSq96IeU-unsplash.c0934419.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: scroll;
}

.motive-iceberg {
  background: url(/static/media/iceberg.600e575e.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
}

.motive-woman-plant {
  background: url(/static/media/woman-plant.fbbcb0ee.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: scroll;
}

.motive-earth-space {
  background: url(/static/media/earth-space.31c002a4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position:  center;
  background-attachment: fixed;
}

.motive-hands {
  background: url(/static/media/hands.c3894db5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.motive-people-silhouette {
  background: url(/static/media/people-silhouette.48b1a11d.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.motive-crowd {
  background: url(/static/media/crowd.8018b1f2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.motive-protest {
  background: url(/static/media/protest.f30d22b5.jpg);
  background-size: cover;
  background-blend-mode: hue;
  background-attachment: fixed;

}
