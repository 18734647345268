html {
  height: 100vh;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Quicksand';
  /*background-color: #342E37; /*#FAFFFD; /* #fafafa;*/
  background-image: linear-gradient(#342E37, #0f0d0f);
}

.landing-bg {
  background-image: url('./assets/img/motives/holding-globe.jpg');
  background-attachment: scroll;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.App {
  /* For sticky footer */
  display: flex;
  flex-direction: column;

  /* For fixed bg on android */
  min-height: 100vh;
  height: 100%;
}

main {
  flex: 1 0 auto;
}

h1, h2, h3, h4, h5 {
  font-family: 'Passion One';
}

.ribbon-stripes {
  background-image: url('assets/img/ribbon-stripes.png');
  background-repeat: repeat-x;
  min-height: 15px;
}

nav {
  height: 64px !important;
}

nav .sidenav-trigger i, .sidenav-trigger {
  height: 64px !important;
  line-height: 64x !important;
}

.sidenav li a {
  color: #FAFFFD;
}

#nav-mobile-green li a {
  color: #7bc340;
}

.card {
  padding: 15px;
}

.card-title {
  font-family: 'Passion One';
}

.btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #93cd62;
}

.spinner-green-only {
  border-color: #93cd62;
}

.valign-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.valign-content {
  flex: 1;
  max-height: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 50px;
}

.nav-wrapper {
  padding: 0 25px 0 25px;
}

.container {
  padding-top: 25px;
}

.lobster {
  font-family: "Lobster";
}

#whatiswcc {
  min-height: 100vh;
}

/* COLORS AND BACKGROUND  */

.wcc-green {
  color: #7bc340;
}

.wcc-green-bg {
  background-color: #7bc340;
}

.wcc-white {
  color: #FAFFFD;
}

.wcc-white-bg {
  background-color: #FAFFFD;
}

.wcc-black {
  color: #342E37;
}

.wcc-black-bg {
  background-color: rgb(52, 46, 55);
}

.wcc-green-to-white {
  background-image: linear-gradient(to right, #7bc340, #FAFFFD, #FAFFFD);
}

.motive-bird {
  background: url('./assets/img/motives/jason-hafso-DS0ZSq96IeU-unsplash.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: scroll;
}

.motive-iceberg {
  background: url('./assets/img/motives/iceberg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
}

.motive-woman-plant {
  background: url('./assets/img/motives/woman-plant.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: scroll;
}

.motive-earth-space {
  background: url('./assets/img/motives/earth-space.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position:  center;
  background-attachment: fixed;
}

.motive-hands {
  background: url('./assets/img/motives/hands.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.motive-people-silhouette {
  background: url('./assets/img/motives/people-silhouette.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.motive-crowd {
  background: url('./assets/img/motives/crowd.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.motive-protest {
  background: url('./assets/img/motives/protest.jpg');
  background-size: cover;
  background-blend-mode: hue;
  background-attachment: fixed;

}